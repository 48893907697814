@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/functions";
@import "~include-media/dist/include-media";

.container {
  width: 100%;
  color: $primary;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  p {
    color: $secondary-darkgrey2;
  }
  @media (max-width: map_get($breakpoints, md)) {
    padding: 0;
    align-items: center;
  }

  &__dark {
    @extend .container;
    background: $primary;
    p, h1 {
      color: #FFFFFF;
    }
  }

  section {
    max-width: rem(1376);
    width: 100%;
  }

  .titleContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: map_get($breakpoints, md)) {
      width: 100%;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 0;
    }
    

    h2 {
      font-size: rem(legacy-rem(45));
      line-height: rem(legacy-rem(100));
      margin: 0 0 15px 0;
      @media (max-width: map_get($breakpoints, lg)) {
        font-size: rem(legacy-rem(35));
        line-height: rem(legacy-rem(90));
        width: rem(legacy-rem(551));
        text-align: left;
      }
      @media (max-width: map_get($breakpoints, md)) {
        text-align: center;
      }
      @media (max-width: map_get($breakpoints, sm)) {
        font-size: rem(legacy-rem(22));
        line-height: rem(legacy-rem(30));
        width: rem(legacy-rem(335));
        text-align: center;
      }
    }
  }

  .descriptionContainer {
    width: 100%;
    max-width: rem(legacy-rem(650));
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: map_get($breakpoints, md)) {
      display: none;
    }
    p {
      font-size: rem(legacy-rem(18));
      line-height: rem(legacy-rem(30));
      margin: 0;
    }
  }

  .galleryContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    @include media(">1300") {
      justify-content: unset;
    }

    .masonryGalleryGrid {
      display: flex;
    }

    .masonryGalleryColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &:nth-child(1) {
        margin-top: rem(65);
        margin-right: rem(36);
      }

      &:nth-child(2) {
        margin-top: rem(165);
        margin-right: rem(36);
      }

      &:nth-child(3) {
        @media (min-width: 1300px) {
          margin-right: rem(36);
        }
      }

      &:nth-child(4) {
        margin-top: rem(65);
      }

      .galleryItem {
        width: 100%;
        max-width: rem(258);
        height: rem(258);
        margin-bottom: rem(36);
        overflow: hidden;
        object-fit: cover;
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        z-index: 100;
        mask-image: url('../../../public/images/masks/gallery-mask.svg');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        -webkit-mask-image: url('../../../public/images/masks/gallery-mask.svg');
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;

        @media (min-width: 1600px) {
          max-width: rem(308);
          height: rem(308);
        }

        &:hover img {
          opacity: 0.5;
        }

        .videoBtn {
          display: none;
        }

        img {
          transition: all 0.5s;
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }

        &:hover .videoBtn {
          width: 100%;
          height: 100%;
          z-index: 2000;
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .topGalleryColumn {
      margin-top: 200px;
      width: rem(legacy-rem(310));
      height: rem(legacy-rem(655));
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-right: rem(legacy-rem(40));

      .galleryItem {
        width: 100%;
        max-width: rem(legacy-rem(310));
        height: rem(legacy-rem(309));
        background: #000;
        border-radius: rem(legacy-rem(20));
        overflow: hidden;
        object-fit: cover;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .responsiveContainer {
    display: none;
    @media (max-width: map_get($breakpoints, lg)) {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding-bottom: rem(legacy-rem(40));
      display: inline-block;
    }

    .imageContainer {
      height: 100%;
      width: rem(legacy-rem(300));
      height: rem(legacy-rem(300));
      mask-image: url('../../../public/images/masks/gallery-mask.svg');
      -webkit-mask-image: url('../../../public/images/masks/gallery-mask.svg');
      mask-size: 100%;
      -webkit-mask-size: 100%;
      -webkit-mask-size: cover;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      // overflow: hidden;
      display: flex;
      justify-content: center;
      border-radius: 15px;
      @media (max-width: map_get($breakpoints, md)) {
        width: rem(legacy-rem(240));
        height: rem(legacy-rem(240));
        height: 100%!important;
        mask-size: 100%;
        -webkit-mask-size: 100%;
        margin-right: rem(legacy-rem(10));
        img {
          height: 100%;
        }
      }

      img {
        width: 105%;
        height: 100%;
        margin: 0;
        padding: 0;
        object-fit: cover;
      }
    }
  }
}

.custom_slider {

    :global(.slick-slide) {
      opacity: 0.5;
    }

    :global(.slick-active) {
      opacity: 1;
    }

    :global(.slick-dots) {
      bottom: rem(legacy-rem(-25));
      & > li {
        margin: 0 rem(legacy-rem(5));
        width: rem(legacy-rem(10));
        height: rem(legacy-rem(10));
        opacity: 0.5;
      }
      & > li > button {
        background: $secondary-red;
        width: rem(legacy-rem(6));
        height: rem(legacy-rem(6));
        border: none;
        outline: none;
        border-radius: 50%;
        margin: 0;

        &::before {
          content: none;
        }
      }
      :global(.slick-active) {
        opacity: 1 !important;
        & > button {
          width: rem(legacy-rem(15))!important;
          height: rem(legacy-rem(15))!important;
        }
      }
    }
  & > div > div {
    display: flex;

    & > div {
      margin-right: rem(legacy-rem(18))!important;
      padding-bottom: rem(legacy-rem(30)) !important;
      width: 40%!important;
    }
  }
}

#top {
  margin-top: 100px;
}

.masonryGrid {
  display: flex;
}

@media (min-width: map_get($breakpoints, md)) {

  .listingGrid {
    display: flex;
    justify-content: center;
  }
  .masonryColumn {
    padding-right: rem(35);
  }

  .masonryColumn ~ .masonryColumn {
    padding-top: rem(50);
    padding-left: rem(35);
    padding-right: 0;
  }
}

@media (max-width: map_get($breakpoints, sm)) {
  .masonryColumn {
    padding-right: rem(50);
    padding-left: rem(50);
    padding-top: 0;
  }
}

.firstColumn {
  justify-self: center;
  @media (max-width: map_get($breakpoints, xxxl)) {
    transform: scaleX(1.05);
  }
  @media (max-width: map_get($breakpoints, md)) {
    transform: scale(1);
  }
}
.secondColumn {
  transform: scaleX(0.95);
  transform-origin: left;
  @media (max-width: map_get($breakpoints, md)) {
    transform: scale(1);
  }
}

.container {
  margin-bottom: rem(100);
}

@media(max-width: map_get($breakpoints, xm)) {
  .container {
    margin: 0 20px;
  }
}

