@use "sass:math";

@import "~include-media/dist/include-media";
@import '../../styles/common';


.container {
  overflow: hidden;
  padding-top: rem(legacy-rem(55));
  padding-bottom: rem(legacy-rem(78));
  width: 100%;

  @media (min-width: map_get($breakpoints, md)) {
    padding-top: rem(legacy-rem(200));
    padding-bottom: rem(legacy-rem(150));
  }

  @media (min-width: map_get($breakpoints, lg)) {
    width: unset;
  }
}

.dark_container {
  padding-top: rem(legacy-rem(55));
  padding-bottom: rem(legacy-rem(78));
  width: 100%;

  @media (min-width: map_get($breakpoints, md)) {
    padding-top: rem(legacy-rem(200));
    padding-bottom: rem(legacy-rem(150));
  }

  @media (min-width: map_get($breakpoints, lg)) {
    width: unset;
  }
}