@import '~include-media/dist/include-media';
@import '../../styles/colors';
@import '../../styles/functions';
.download {
  width: 100%;

  & > ul {
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    & > li {
      flex: 0 0 100%;
      padding: 0;
      display: flex;
      align-items: flex-end;
      overflow: hidden;

      a {
        display: inline-block;
        overflow: hidden;
      }

      .downloadLink {
        position: relative;
        font-family: $formInputFont;
        color: $secondary-darkgrey2;
        align-items: center;
        list-style-type: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        text-decoration: none;
        transition: all 0.5s ease-in-out;

        &::before {
          content: " ";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 1.5px;
          background-color: rgba(112, 112, 112, 0.3);
          transition: all 0.5s ease-in-out;
        }

        &:hover {
          &::before {
            height: 2px;
            background-color: $brand-pink;
          }
          cursor: pointer;

          .downloadTitle {
            color: #000;
          }
          .downloadButton {
            div {
              font-weight: $bold;
            }
            color: $brand-pink;
            animation: Gradient 0.5s ease-in-out;
          }
          .downloadType {
            opacity: 1;
          }

          svg {
            color: #FF6274;
            animation: Gradientsvg 0.5s ease-in-out;
          }
        }
        div {
          padding: rem(legacy-rem(37)) rem(legacy-rem(20)) rem(legacy-rem(25)) rem(legacy-rem(2));
          display: flex;
          align-items: flex-end;
          font-size: rem(legacy-rem(20));

        }
        .downloadType {
          flex: 0 0 auto;
          color: $primary;
          fill: $primary;
          opacity: 50%;

          svg {
            font-size: 10px;
            transform: scale(1.1,1.1);
            position: inherit;
            width: rem(legacy-rem(17));
          }
          @media (max-width: map_get($breakpoints, lg)) {
            padding-right: rem(legacy-rem(10));
            order: 2;

            svg {
              transform: scale(1,1);
              position: inherit;
              width: rem(legacy-rem(17));
            }
          }
        }
        .downloadTitle {
          flex: 0 0 auto;
          font-size: rem(20);
          line-height: rem(35);
          max-width: 70%;

          @media (max-width: map_get($breakpoints, lg)) {
            padding-bottom: 0;
            font-size: rem(legacy-rem(16));
            line-height: rem(legacy-rem(28));
            flex: 0 0 100%;
            order: 1;
            padding-top: rem(20);
          }
        }
        .downloadSize {
          height: rem(legacy-rem(100));
          color: #717274;
          opacity: 0.7;
          margin-left: auto;
          padding-right: rem(legacy-rem(23));
          font-family: $bodyFont;
          font-size: rem(legacy-rem(16));
          line-height: rem(legacy-rem(35));
          justify-content: flex-end;

          @media (max-width: map_get($breakpoints, lg)) {
            margin-left: 0;
            padding-top: 0;
            font-size: rem(legacy-rem(14));
            padding-left: 0;
            flex: 0 0 auto;
            order: 3;
            justify-content: flex-start;
          }
        }
        .downloadButton {
          height: rem(legacy-rem(100));
          font-weight: $bold;
          align-items: center;
          font-size: rem(legacy-rem(18));
          flex: 0 0 auto;
          padding: 0;
          margin-right: 15px;
          margin-left: 0;
          svg {
            color: $brand-pink;
            fill: $brand-pink;
            width: rem(legacy-rem(17));
            margin-bottom: rem(legacy-rem(-10));
          }
          div {
            padding-bottom: rem(legacy-rem(25));
          }

          @media (max-width: map_get($breakpoints, lg)) {
            margin-left:auto;
            order: 4;
            div {
              font-size: rem(legacy-rem(15));
              padding-right: rem(legacy-rem(10));
            }
          }
        }
      }
    }
  }
}

@keyframes Gradientsvg {
  0% {
      color: rgba(136,140,144)
  }
  100% {
      color: #FF6274
  }
}

@keyframes Gradient {
  0% {
      color: $primary
  }
  100% {
      color: #FF6274
  }
}

.constrained {
  width: 75% !important;
  margin: 0 auto;

  @media (max-width: map_get($breakpoints, lg)) {
    width: 100%;
  }
}

.download {
  padding: 0 rem(94.5);
}
