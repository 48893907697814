@import "../../styles/functions";

.container {
  width: 100%;
  position: relative;
  height: 100%;

  & > div > div {
    width: 100%;
  }

  .masonryGalleryGrid {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    & > div:nth-child(1) {
      margin-top: rem(70);
    }

    & > div:nth-child(2) {
      margin-top: rem(160);
    }

    & > div:nth-child(3) {
      margin-top: rem(80);
    }

    & > div:nth-child(4) {
      margin-top: rem(150)
    }

    & > div:nth-child(6) {
      margin-top: rem(80);
    }
  }
}

.cardContainer {
  max-width: rem(1520);
  margin: 0 auto;
}
